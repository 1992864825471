import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
const Settings = Loadable(lazy(() => import('../pages/Controls/Settings')));
const AppContactUS = Loadable(lazy(() => import('../pages/contact-us')));
const UserProfile = Loadable(lazy(() => import('../pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

const AllContactList = Loadable(lazy(() => import('../pages/blogList/list')));
const AllEnquiryList = Loadable(lazy(() => import('../pages/blogList/InqueryList')));
const AllEmailNewsList = Loadable(lazy(() => import('../pages/blogList/EmailNewsList')));
const BlogCategoryList = Loadable(lazy(() => import('../pages/blogCategory/list')));
const BlogCategoryForm = Loadable(lazy(() => import('../pages/blogCategory/form')));
const BlogList = Loadable(lazy(() => import('../pages/blog/list')));
const BlogForm = Loadable(lazy(() => import('../pages/blog/form')));
const FaqList = Loadable(lazy(() => import('../pages/faq/list')));
const FaqForm = Loadable(lazy(() => import('../pages/faq/form')));
const Policy = Loadable(lazy(() => import('../pages/policy/form')));

const Chat = Loadable(lazy(() => import('../pages/apps/chat'))); //chat

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },

        {
          path: 'blog-category',
          children: [
            {
              path: 'list',
              element: <BlogCategoryList />
            },
            {
              path: 'addform/:id',
              element: <BlogCategoryForm />
            },
            {
              path: 'addform',
              element: <BlogCategoryForm />
            }
          ]
        },
        {
          path: 'blog',
          children: [
            {
              path: 'list',
              element: <BlogList />
            },
            {
              path: 'addform/:id',
              element: <BlogForm />
            },
            {
              path: 'addform',
              element: <BlogForm />
            }
          ]
        },
        {
          path: 'career',
          children: [
            {
              path: 'list',
              element: <AllContactList />
            }
          ]
        },
        {
          path: 'enquiry',
          children: [
            {
              path: 'list',
              element: <AllEnquiryList />
            }
          ]
        },
        {
          path: 'email-news',
          children: [
            {
              path: 'list',
              element: <AllEmailNewsList />
            }
          ]
        },
        {
          path: 'faq',
          children: [
            {
              path: 'list',
              element: <FaqList />
            },
            {
              path: 'addform/:id',
              element: <FaqForm />
            },
            {
              path: 'addform',
              element: <FaqForm />
            }
          ]
        },
        {
          path: 'policy',
          element: <Policy />
        },
        {
          path: 'controls',
          children: [
            {
              path: 'settings',
              element: <Settings />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    }
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                }
              ]
            },
            {
              path: 'chat',
              element: <Chat />
            },
            {
              path: 'chat/:id/:mid',
              element: <Chat />
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;
