export interface BlogCategoryInterface {
  _id: string;
  title: string;
  isActive: IsActivityEnum;
}
export interface BlogCategoryAllInterface {
  _id: string;
  title: string;
}
export enum IsActivityEnum {
  INACTIVE = 2,
  ACTIVE = 1
}
export interface BlogCategoryState {
  list: BlogCategoryInterface[];
  allCategory: BlogCategoryAllInterface[];
  form: BlogCategoryInterface;
  editIndex: number;
  loading: boolean;
  buttonLoading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialBlogCategoryState = BlogCategoryState;
