import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  doAddBlog,
  doGetBlogList,
  doGetBlogEdit,
  doUpdateBlog,
  doUpdateVisibleOnHomeStatus,
  doUpdateVisibleOnBannerStatus,
  doDeleteBlog,
  doUploadImage
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { BlogInterface } from './types/index';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: BlogInterface = yield select(selectForm);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.date.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select date',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.createdBy.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill created by',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.categoryId.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select category',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.shortDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill short description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.description.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.metaTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill meta title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.metaDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill meta description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddBlog, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetBlogList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetBlogEdit, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: BlogInterface = yield select(selectForm);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.metaTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill meta title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.metaDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill meta description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateBlog, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.message == 'Network Error') {
      yield put(
        openSnackbar({
          open: true,
          message: 'Network Error',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    CatchBlockFunction(error);
  }
}
export function* doDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doDeleteBlog, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUploadImageRequest(action: { payload: { file: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doUploadImage, action.payload.file);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield put(
      actions.updateFormValue({
        key: 'image',
        value: response.data.data
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateIsVisibleOnHomeStatusRequest(action: {
  payload: {
    id: string;
    status: boolean;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    const data = {
      isVisibleOnHome: action.payload.status
    };
    const response: AxiosResponse = yield call(doUpdateVisibleOnHomeStatus, action.payload.id, data);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateIsVisibleOnBannerStatusRequest(action: {
  payload: {
    id: string;
    status: boolean;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    const data = {
      isVisibleOnBanner: action.payload.status
    };
    const response: AxiosResponse = yield call(doUpdateVisibleOnBannerStatus, action.payload.id, data);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* BlogRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
  yield takeLatest(actions.doUpdateVisibleOnHome, doUpdateIsVisibleOnHomeStatusRequest);
  yield takeLatest(actions.doUpdateVisibleOnBanner, doUpdateIsVisibleOnBannerStatusRequest);
  yield takeLatest(actions.doAddBlogImage, doUploadImageRequest);
}
