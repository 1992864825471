import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { PolicyInterface } from '../types';
import { selectForm } from './selector';
import { doGetPolicy, doUpdatePolicy } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* doGetEditRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetPolicy);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: PolicyInterface = yield select(selectForm);

  if (form.privacyPolicyDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill privacy policy',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.tmcDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill terms and conditions',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.dataProtectionPolicy.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill data protection policy',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.serviceAndSupport.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill service and support',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdatePolicy, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* PolicyRepoSaga() {
  yield takeLatest(actions.doGetData, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
}
