import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.policyState) {
    return state.policyState;
  } else {
    return initialState;
  }
};

export const selectForm = createSelector([selectDomain], (state) => state.form);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);