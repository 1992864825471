import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.initialState) {
    return state.initialState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectContactList = createSelector([selectDomain], (state) => state.List);
export const selectEmailNewsList = createSelector([selectDomain], (state) => state.emailNewsList);
export const selectEnquiryList = createSelector([selectDomain], (state) => state.enquiryList);
export const selectSearch = createSelector([selectDomain], (state) => state.search);
export const selectEnquirySearch = createSelector([selectDomain], (state) => state.enquirySearch);
export const selectEmailSearch = createSelector([selectDomain], (state) => state.emailSearch);
export const selectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const selectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const selectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const selectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const selectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const selectEmailNewsPageNo = createSelector([selectDomain], (state) => state.emailNewsPageNo);
export const selectEmailNewsPageSize = createSelector([selectDomain], (state) => state.emailNewsPageSize);
export const selectEmailNewsTotalRow = createSelector([selectDomain], (state) => state.emailNewsTotalRow);
export const selectEmailNewsSortColumn = createSelector([selectDomain], (state) => state.emailNewsSortColumn);
export const selectEmailNewsSortDir = createSelector([selectDomain], (state) => state.emailNewsSortDir);
export const selectEnquiryPageNo = createSelector([selectDomain], (state) => state.enquiryPageNo);
export const selectEnquiryPageSize = createSelector([selectDomain], (state) => state.enquiryPageSize);
export const selectEnquiryTotalRow = createSelector([selectDomain], (state) => state.enquiryTotalRow);
export const selectEnquirySortColumn = createSelector([selectDomain], (state) => state.enquirySortColumn);
export const selectEnquirySortDir = createSelector([selectDomain], (state) => state.enquirySortDir);
export const selectCategory = createSelector([selectDomain], (state) => state.category);
export const selectSegment = createSelector([selectDomain], (state) => state.segment);
export const selectSource = createSelector([selectDomain], (state) => state.source);
export const selectEnquiryDetails = createSelector([selectDomain], (state) => state.enquiryDetails);
