export enum isActiveEnum {
  active = 1,
  notActive = 0
}

export enum DealershipInvestmentTypeEnum {
  'Below_20 lakhs' = 1,
  '20 Lakh - 30 lakhs' = 2,
  '30 Lakh - 50 lakhs' = 3,
  'Above 50 lakhs' = 4
}
export enum PurchaseTimeTypeEnum {
  'Immediately' = 1,
  'In This Week' = 2,
  '2-3 Weeks' = 3,
  'In a Month' = 4,
  'Later' = 5
}
export enum DealershipTypeEnum {
  E2W = 1,
  E3W = 2
}
export enum ProductTypeEnum {
  DRIXX = 1,
  LEGION = 2,
  ZGS = 3,
  TRENTO = 4
}
export enum ShowroomStatusEnum {
  NOT_READY = 1,
  OWNED = 2,
  LEASED = 3
}
export enum InquiryStatusEnum {
  Inquiry = 1,
  Resolved = 2
}
export enum CategoryTypeEnum {
  All = 0,
  TWO_WHEELER = '2 Wheeler',
  THREE_WHEELER = '3 Wheeler'
}

export enum SegmentTypeEnum {
  All = 0,
  B2B = 'B2B',
  B2C = 'B2C'
}

export enum SourceTypeEnum {
  All = 0,
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook'
}
export enum InquiryTypeEnum {
  DEALERSHIP = 'Dealership',
  CUSTOMER = 'Customer'
}
export interface ContactUsInterface {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  mobileNumber: string;
  department: string;
  tentativeDateOfJoining: string;
  file: string;
  createdAt: string;
  updatedAt: string;
}
export interface EmailNewsInterface {
  _id: string;
  email: string;
  createdAt: string;
}
export interface EnquiryInterface {
  _id: string;
  fullName: string;
  email: string;
  dealershipType: DealershipTypeEnum;
  mobileNumber: string;
  investmentType: DealershipInvestmentTypeEnum;
  showroomStatus: ShowroomStatusEnum;
  status: InquiryStatusEnum;
  updatedAt: string;
  inquiryType: InquiryTypeEnum;
  state: string;
  city: string;
  createdAt: string;
  interestedProduct: ProductTypeEnum;
  purchasingTime: PurchaseTimeTypeEnum;
  category: CategoryTypeEnum;
  segment: SegmentTypeEnum;
  source: SourceTypeEnum;
  utm_source: string;
  utm_medium: string;
  campaign_id: string;
  adgroup_id: string;
  Keyword_Id: string;
  Network: string;
  Device: string;
  Matchtype: string;
  Location: string;
  Keyword: string;
  Placement: string;
  Ad_Position: string;
  gcl_Id: string;
}
export interface InitialListState {
  List: ContactUsInterface[];
  emailNewsList: EmailNewsInterface[];
  enquiryList: EnquiryInterface[];
  enquiryDetails: EnquiryInterface;
  loading: boolean;
  search: string;
  emailSearch: string;
  enquirySearch: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  sortColumn: string;
  sortDir: string;
  emailNewsPageNo: number;
  emailNewsPageSize: number;
  emailNewsTotalRow: number;
  emailNewsSortColumn: string;
  emailNewsSortDir: string;
  enquiryPageNo: number;
  enquiryPageSize: number;
  enquiryTotalRow: number;
  enquirySortColumn: string;
  enquirySortDir: string;
  category: CategoryTypeEnum;
  segment: SegmentTypeEnum;
  source: SourceTypeEnum;
}

export type InitialBlogState = InitialListState;
