import dashboard from './dashboard';
import { NavItemType } from 'types/menu';
import custom from './custom';
import { RolesEnum } from 'sections/auth/types';

// ==============================|| MENU ITEMS ||============================== //

const menuItemss = (Role?: RolesEnum): { items: NavItemType[] } => {
  console.log('====================================');
  console.log('Role', Role);
  console.log('====================================');
  const menuItems: { items: NavItemType[] } = {
    items: [dashboard, custom]
    // Role == RolesEnum.ADMIN
    //   ? [dashboard, custom]
    //   : Role == RolesEnum.TEACHER
    //   ? [dashboard, custom]
    //   : Role == RolesEnum.STUDENT
    //   ? [dashboard, custom]
    //   : []
  };

  return menuItems;
};

export default menuItemss;
