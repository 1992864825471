export interface loginFromData {
  username: string;
  password: string;
  errors: Array<string>;
  success: string;
}
export interface UpdatePasswordInterface {
  id: string;
  password: string;
  newPassword: string;
}
export interface moduleInterface {
  name: string;
   value: string;
  rights: rightsInterface[]
 }
 export interface rightsInterface {
   title: string;
   name: string;
   value: number
 }
 export enum IsActiveEnum {
   INACTIVE = 0,
   ACTIVE = 1
 } 
export interface StaffInterface {
  id: string;
  _id: string;
  name: string;
  email: string;
  password: string;
  isActive: IsActiveEnum;
  module: moduleInterface[]
}

export interface AuthState {
  loginFrom: {
    username: string;
    password: string;
    errors: Array<string>;
    success: string;
  };
  userData: {
    firstName: string;
    lastName: string;
    email: string;
    usertype: string;
    profilePic: string;
  };
  updatePasswordFormData: UpdatePasswordInterface;
  loading: boolean;
  token: string;
  form: StaffInterface;
  isLogin: boolean;
}
export enum RolesEnum {
  ADMIN = 1,
  TEACHER = 2,
  STUDENT = 3,
  ANONYMOUS = 4
}

export enum ClinicErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401
}

export type InitialAuthState = AuthState;
