// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from './store/reducers/menu';
import { sliceKey as SnackbarsliceKey, reducer as SnackbarReducer } from './store/reducers/snackbar';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from './sections/auth/redux/slice';
import { authRepoSaga } from './sections/auth/redux/saga';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/redux/slice';
import { authRepoSagaDashboard } from 'pages/dashboard/redux/saga';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';
//TrendingBlog
import { sliceKey as InitialSliceKey, reducer as InitialReducer } from 'pages/blogList/slice';
import { InitialRepoSaga } from 'pages/blogList/saga';
import { sliceKey as BlogCategorySliceKey, reducer as BlogCategoryReducer } from 'pages/blogCategory/redux/slice';
import { BlogCategoryRepoSaga } from 'pages/blogCategory/redux/saga';
import { sliceKey as BlogSliceKey, reducer as BlogReducer } from 'pages/blog/redux/slice';
import { BlogRepoSaga } from 'pages/blog/redux/saga';
import { sliceKey as FaqSliceKey, reducer as FaqReducer } from 'pages/faq/redux/slice';
import { FaqRepoSaga } from 'pages/faq/redux/saga';
import { sliceKey as PolicySliceKey, reducer as PolicyReducer } from 'pages/policy/redux/slice';
import { PolicyRepoSaga } from 'pages/policy/redux/saga';

const App = () => {
  useInjectReducer({ key: SnackbarsliceKey, reducer: SnackbarReducer });
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });

  //******Order List Section******//
  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: authRepoSagaDashboard });

  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser });
  useInjectReducer({ key: InitialSliceKey, reducer: InitialReducer });
  useInjectSaga({ key: InitialSliceKey, saga: InitialRepoSaga }); //blogList
  useInjectReducer({ key: BlogCategorySliceKey, reducer: BlogCategoryReducer });
  useInjectSaga({ key: BlogCategorySliceKey, saga: BlogCategoryRepoSaga }); //blog category
  useInjectReducer({ key: BlogSliceKey, reducer: BlogReducer });
  useInjectSaga({ key: BlogSliceKey, saga: BlogRepoSaga }); //blog category
  useInjectReducer({ key: FaqSliceKey, reducer: FaqReducer });
  useInjectSaga({ key: FaqSliceKey, saga: FaqRepoSaga }); //Faq
  useInjectReducer({ key: PolicySliceKey, reducer: PolicyReducer });
  useInjectSaga({ key: PolicySliceKey, saga: PolicyRepoSaga }); //Policy

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
