import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';

import { CategoryTypeEnum, ContactUsInterface, DealershipInvestmentTypeEnum, DealershipTypeEnum, EmailNewsInterface, EnquiryInterface, InquiryStatusEnum, InquiryTypeEnum, ProductTypeEnum, PurchaseTimeTypeEnum, SegmentTypeEnum, ShowroomStatusEnum, SourceTypeEnum } from './types';

export const useClinicSlice = createSlice({
  name: 'initialState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setEmailSearch: (state, action: PayloadAction<string>) => {
      state.emailSearch = action.payload;
    },
    setEnquirySearch: (state, action: PayloadAction<string>) => {
      state.enquirySearch = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setEmailNewsPageNo: (state, action: PayloadAction<number>) => {
      state.emailNewsPageNo = action.payload;
    },
    setEnquiryPageNo: (state, action: PayloadAction<number>) => {
      state.enquiryPageNo = action.payload;
    },
    setEmailNewsPageSize: (state, action: PayloadAction<number>) => {
      state.emailNewsPageSize = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setEnquiryPageSize: (state, action: PayloadAction<number>) => {
      state.enquiryPageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setEmailNewsTotalRow: (state, action: PayloadAction<number>) => {
      state.emailNewsTotalRow = action.payload;
    },
    setEnquiryTotalRow: (state, action: PayloadAction<number>) => {
      state.enquiryTotalRow = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setEmailNewsSortColumn: (state, action: PayloadAction<string>) => {
      state.emailNewsSortColumn = action.payload;
    },
    setEnquirySortColumn: (state, action: PayloadAction<string>) => {
      state.enquirySortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setEmailNewsSortDir: (state, action: PayloadAction<string>) => {
      state.emailNewsSortDir = action.payload;
    },
    setEnquirySortDir: (state, action: PayloadAction<string>) => {
      state.enquirySortDir = action.payload;
    },

    doGetCareerList: (state) => {},
    doGetEmailNewsList: (state) => {},
    doGetEnquiryList: (state) => {},

    setContactList: (state, action: PayloadAction<Array<ContactUsInterface>>) => {
      state.List = action.payload;
    },
    setEmailNewsList: (state, action: PayloadAction<Array<EmailNewsInterface>>) => {
      state.emailNewsList = action.payload;
    },
    setEnquiryList: (state, action: PayloadAction<Array<EnquiryInterface>>) => {
      state.enquiryList = action.payload;
    },
    doUpdateStatusData: (
      state,
      action: PayloadAction<{
        id: string;
        status: number;
        callback: () => void;
      }>
    ) => {},
    doUpdateEnquiryStatusData: (
      state,
      action: PayloadAction<{
        id: string;
        status: number;
        callback: () => void;
      }>
    ) => {},
    doDelete: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    setCategory: (state, action: PayloadAction<CategoryTypeEnum>) => {
      state.category = action.payload;
    },
    setSegment: (state, action: PayloadAction<SegmentTypeEnum>) => {
      state.segment = action.payload;
    },
    setSource: (state, action: PayloadAction<SourceTypeEnum>) => {
      state.source = action.payload;
    },
    doShowEnquiryDetails: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    setEnquiryDetails: (state, action: PayloadAction<EnquiryInterface>) => {
      state.enquiryDetails = action.payload;
    },
    clearEnquiryDetails: (state) => {
      state.enquiryDetails._id = '';
      state.enquiryDetails.fullName = '';
      state.enquiryDetails.email = '';
      state.enquiryDetails.dealershipType = DealershipTypeEnum.E2W;
      state.enquiryDetails.mobileNumber = '';
      state.enquiryDetails.investmentType = DealershipInvestmentTypeEnum['20 Lakh - 30 lakhs'];
      state.enquiryDetails.showroomStatus = ShowroomStatusEnum.NOT_READY;
      state.enquiryDetails.status = InquiryStatusEnum.Inquiry;
      state.enquiryDetails.updatedAt = '';
      state.enquiryDetails.inquiryType = InquiryTypeEnum.CUSTOMER;
      state.enquiryDetails.state = '';
      state.enquiryDetails.city = '';
      state.enquiryDetails.interestedProduct = ProductTypeEnum.DRIXX;
      state.enquiryDetails.purchasingTime = PurchaseTimeTypeEnum['2-3 Weeks'];
      state.enquiryDetails.category = CategoryTypeEnum.All;
      state.enquiryDetails.segment = SegmentTypeEnum.All;
      state.enquiryDetails.source = SourceTypeEnum.All;
      state.enquiryDetails.utm_source = '';
      state.enquiryDetails.utm_medium = '';
      state.enquiryDetails.campaign_id = '';
      state.enquiryDetails.adgroup_id = '';
      state.enquiryDetails.Keyword_Id = '';
      state.enquiryDetails.Network = '';
      state.enquiryDetails.Device = '';
      state.enquiryDetails.Matchtype = '';
      state.enquiryDetails.Location = '';
      state.enquiryDetails.Keyword = '';
      state.enquiryDetails.Placement = '';
      state.enquiryDetails.Ad_Position = '';
      state.enquiryDetails.gcl_Id = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
