import { BlogState } from '.';

export const initialState: BlogState = {
  loading: false,
  form: {
    _id: '',
    title: '',
    date: '',
    category: '',
    categoryId: '',
    shortDescription: '',
    description: '',
    createdBy: '',
    image: 'image',
    isVisibleOnHome: false,
    isVisibleOnBanner: false,
    imageStatus: [],
    metaTitle: '',
    metaDescription: '',
    createdAt: '',
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  uploadFile: []
};
