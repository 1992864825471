import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { selectLoginForm, selectUpdatePassword } from './selector';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { LoginRequest, UpdatePassword } from 'utils copy/request';
import { MeProfileRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { openSnackbar } from 'store/reducers/snackbar';
import { UpdatePasswordInterface } from '../types';

export function* doMeRequest() {
  yield delay(500);
  if (localStorage.getItem('token')) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.status) {
        openSnackbar({
          open: true,
          message: 'response.message',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        return;
      }
      yield put(
        actions.setProfileData({
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          email: response.data.data.email,
          usertype: response.data.data.role,
          profilePic: ''
        })
      );

      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}

export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username, password } = yield select(selectLoginForm);
  if (username.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'enter username',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  let data = {
    email: username,
    password: btoa(password)
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(LoginRequest, data);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      actions.setProfileData({
        firstName: '',
        lastName: '',
        email: '',
        usertype: '',
        profilePic: ''
      })
    );
    yield put(actions.setUpdateToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
    yield put(actions.getUserProfile());

    yield put(actions.loginSuccess());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePasswordRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UpdatePasswordInterface = yield select(selectUpdatePassword);
  if (form.password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.newPassword.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter new password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  let data = {
    newPassword: btoa(form.newPassword),
    password: btoa(form.password)
  };

  try {
    const response: AxiosResponse = yield call(UpdatePassword, data);

    if (response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.getUserProfile, doMeRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
}
