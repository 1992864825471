import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import errorCode from './ErrorCode/Index';

export default function CatchBlockFunction(error: any) {
  if (errorCode(error?.response?.data.statusCode)) {
    dispatch(
      openSnackbar({
        open: true,
        message: error.response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  } else {
    if (error.message !== 'e is undefined') {
      if (error.message !== "undefined is not an object (evaluating 'e.context')") {
        if (error.message !== "Cannot read properties of undefined (reading 'context')") {
          if (error.message !== "Cannot read properties of undefined (reading '0')") {
            if (error.message !== 'call: argument fn is undefined or null') {
              if (error.message !== "Cannot read properties of undefined (reading 'results')") {
                if (error.response) {
                  if (error.response.status !== 401) {
                    if (error.response.data.errors) {
                      let dataerrer3 = error.response.data.errors.map((item: any) => {
                        return {
                          name: item.property,
                          message: item.message
                        };
                      });
                      for (let index = 0; index < dataerrer3.length; index++) {
                        const element = dataerrer3[index];
                        dispatch(
                          openSnackbar({
                            open: true,
                            message: element.message,
                            variant: 'alert',
                            alert: {
                              color: 'error'
                            },
                            close: true
                          })
                        );
                      }
                    } else {
                      dispatch(
                        openSnackbar({
                          open: true,
                          message: error.response.data.message,
                          variant: 'alert',
                          alert: {
                            color: 'error'
                          },
                          close: true
                        })
                      );
                    }
                  } else {
                    localStorage.removeItem('token');
                  }
                } else {
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: error.message,
                      variant: 'alert',
                      alert: {
                        color: 'error'
                      },
                      close: true
                    })
                  );
                }
              }
            }
          }
        }
      }
    }
  }
}
