export interface FaqInterface {
  _id: string;
  question: string;
  answer: string;
  isActive: IsActivityEnum;
}

export enum IsActivityEnum {
  INACTIVE = 2,
  ACTIVE = 1
}
export interface FaqState {
  list: FaqInterface[];
  form: FaqInterface;
  editIndex: number;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialFaqState = FaqState;
