import { PayloadAction } from '@reduxjs/toolkit';
import { PolicyState, PolicyInterface } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: PolicyState = {
  loading: false,
  form: {
    privacyPolicyDescription: '',
    dataProtectionPolicy: '',
    serviceAndSupport: '',
    tmcDescription: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'policyState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetData: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setDataForEdit: (state, action: PayloadAction<PolicyInterface>) => {
      state.form = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
