import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import {
  selectCategory,
  selectEmailNewsPageNo,
  selectEmailNewsPageSize,
  selectEmailNewsSortColumn,
  selectEmailNewsSortDir,
  selectEmailSearch,
  selectEnquiryPageNo,
  selectEnquiryPageSize,
  selectEnquirySearch,
  selectEnquirySortColumn,
  selectEnquirySortDir,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectSegment,
  selectSortColumn,
  selectSortDir,
  selectSource
} from './selector';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import {
  DeleteEmailNews,
  GetCareerList,
  GetEmailNewsList,
  GetEnquiryList,
  ShowEnquiryDetails,
  UpdateUserContactStatus,
  UpdateUserEnquiryStatus
} from 'utils copy/request';
import { CategoryTypeEnum, SegmentTypeEnum, SourceTypeEnum } from './types';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const sortColumn: string = yield select(selectSortColumn);
    const sortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCareerList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setContactList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEmailNewsListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectEmailNewsPageNo);
    const pageSize: number = yield select(selectEmailNewsPageSize);
    const searchText: string = yield select(selectEmailSearch);
    const sortColumn: string = yield select(selectEmailNewsSortColumn);
    const sortDir: string = yield select(selectEmailNewsSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetEmailNewsList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setEmailNewsTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setEmailNewsList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEnquiryListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectEnquiryPageNo);
    const pageSize: number = yield select(selectEnquiryPageSize);
    const searchText: string = yield select(selectEnquirySearch);
    const sortColumn: string = yield select(selectEnquirySortColumn);
    const sortDir: string = yield select(selectEnquirySortDir);
    const category: CategoryTypeEnum = yield select(selectCategory);
    const segment: SegmentTypeEnum = yield select(selectSegment);
    const source: SourceTypeEnum = yield select(selectSource);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetEnquiryList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${category != 0 ? '&category=' + category : ''}${segment != 0 ? '&segment=' + segment : ''}${
        source != 0 ? '&source=' + source : ''
      }${searchText.length > 0 ? '&search=' + searchText : ''}${sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''}${
        sortDir.length > 0 ? '&sortDir=' + sortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setEnquiryTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setEnquiryList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doUpdateContactStatusRequest(action: {
  payload: {
    id: string;
    status: number;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const data = {
      queryStatus: action.payload.status
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateUserContactStatus, action.payload.id, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateEnquiryStatusRequest(action: {
  payload: {
    id: string;
    status: number;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const data = {
      queryStatus: action.payload.status
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateUserEnquiryStatus, action.payload.id, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doEmailNewsRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(DeleteEmailNews, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doShowEnquiryDetailsRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(ShowEnquiryDetails, action.payload.id);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setEnquiryDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* InitialRepoSaga() {
  yield takeLatest(actions.doGetCareerList, doGetListRequest);
  yield takeLatest(actions.doGetEmailNewsList, doGetEmailNewsListRequest);
  yield takeLatest(actions.doGetEnquiryList, doGetEnquiryListRequest);
  yield takeLatest(actions.doUpdateStatusData, doUpdateContactStatusRequest);
  yield takeLatest(actions.doUpdateEnquiryStatusData, doUpdateEnquiryStatusRequest);
  yield takeLatest(actions.doDelete, doEmailNewsRequest);
  yield takeLatest(actions.doShowEnquiryDetails, doShowEnquiryDetailsRequest);
}
