import Api from './Api';
const LoginRequest = async (data: any) => {
  return Api.post(`admin/login`, data);
};
const MeProfileRequest = async () => {
  return Api.get(`auth/user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const UpdatePassword = async (data: any) => {
  return Api.patch(`/change-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetCareerList = async (filter: string) => {
  return Api.get(`career/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetEnquiryList = async (filter: string) => {
  return Api.get(`user/get/dealership/inquiries${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetEmailNewsList = async (filter: string) => {
  return Api.get(`user/get/newsletter/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateUserContactStatus = async (id: string, data: any) => {
  return Api.put(`career/get${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const ShowEnquiryDetails = async (id: string) => {
  return Api.get(`user/get/inquiries/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateUserEnquiryStatus = async (id: string, data: any) => {
  return Api.put(`user/dealership/inquiry/update/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteEmailNews = async (id: string) => {
  return Api.delete(`user/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//blog category section start//
const doAddBlogCategory = async (data: any) => {
  return Api.post(`blog-category/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBlogCategoryList = async (filter: string) => {
  return Api.get(`blog-category/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBlogCategoryEdit = async (id: string) => {
  return Api.get(`blog-category/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateBlogCategory = async (data: any) => {
  return Api.put(`blog-category/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateStatus = async (id: string, data: any) => {
  return Api.put(`blog-category/update-status/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteBlogCategory = async (id: string) => {
  return Api.delete(`/blog-category/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBlogCategoryAll = async () => {
  return Api.get(`/blog-category/get-all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//blog category section start//
//blog section start//
const doAddBlog = async (data: any) => {
  return Api.post(`blog/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBlogList = async (filter: string) => {
  return Api.get(`blog/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetBlogEdit = async (id: string) => {
  return Api.get(`blog/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateBlog = async (data: any) => {
  return Api.put(`blog/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateVisibleOnHomeStatus = async (id: string, data: any) => {
  return Api.put(`blog/update-home-visiable/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateVisibleOnBannerStatus = async (id: string, data: any) => {
  return Api.put(`blog/update-banner-visiable/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteBlog = async (id: string) => {
  return Api.delete(`/blog/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUploadImage = async (data: any) => {
  return Api.put(`blog/upload`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//blog section start//
//blog category section start//
const doAddFaq = async (data: any) => {
  return Api.post(`faq/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetFaqList = async (filter: any) => {
  return Api.get(`faq/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetFaqEdit = async (id: string) => {
  return Api.get(`faq/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateFaq = async (data: any) => {
  return Api.put(`faq/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteFaq = async (id: string) => {
  return Api.delete(`/faq/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//blog category section end//
//policy section start//
const doGetPolicy = async () => {
  return Api.get(`policy/get`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdatePolicy = async (data: any) => {
  return Api.put(`policy/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//policy section end//

export {
  LoginRequest,
  MeProfileRequest,
  UpdatePassword,
  GetCareerList,
  GetEmailNewsList,
  GetEnquiryList,
  UpdateUserContactStatus,
  UpdateUserEnquiryStatus,
  DeleteEmailNews,
  doAddBlogCategory,
  doGetBlogCategoryList,
  doGetBlogCategoryEdit,
  doUpdateBlogCategory,
  doUpdateStatus,
  doDeleteBlogCategory,
  doGetBlogCategoryAll,
  doAddBlog,
  doGetBlogList,
  doGetBlogEdit,
  doUpdateBlog,
  doUpdateVisibleOnHomeStatus,
  doUpdateVisibleOnBannerStatus,
  doDeleteBlog,
  doUploadImage,
  doAddFaq,
  doGetFaqList,
  doGetFaqEdit,
  doUpdateFaq,
  doGetPolicy,
  doUpdatePolicy,
  DeleteFaq,
  ShowEnquiryDetails
};
