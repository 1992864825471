import { PayloadAction } from '@reduxjs/toolkit';
import { BlogInterface } from './types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { initialState } from './types/initialState';
import moment from 'moment';

export const useClinicSlice = createSlice({
  name: 'blogState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.title = '';
      state.form.category = '';
      state.form.categoryId = '';
      state.form.createdBy = '';
      state.form.date = '';
      state.form.image = 'image';
      state.form.shortDescription = '';
      state.form.description = '';
      state.form.isVisibleOnBanner = false;
      state.form.isVisibleOnHome = false;
      state.form.imageStatus = [];
      state.form.metaTitle = "";
      state.form.metaDescription = "";
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateVisibleOnHome: (
      state,
      action: PayloadAction<{
        id: string;
        status: boolean;
        callback: () => void;
      }>
    ) => {},
    doUpdateVisibleOnBanner: (
      state,
      action: PayloadAction<{
        id: string;
        status: boolean;
        callback: () => void;
      }>
    ) => {},

    doDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setList: (state, action: PayloadAction<Array<BlogInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<BlogInterface>) => {
      state.form._id = action.payload._id;
      state.form.category = action.payload.category;
      state.form.categoryId = action.payload.categoryId;
      state.form.createdBy = action.payload.createdBy;
      // state.form.date = action.payload.date;
      state.form.date = moment(action.payload.date).format('YYYY-MM-DD');
      state.form.description = action.payload.description;
      state.form.image = action.payload.image;
      state.form.isVisibleOnBanner = action.payload.isVisibleOnBanner;
      state.form.isVisibleOnHome = action.payload.isVisibleOnHome;
      state.form.shortDescription = action.payload.shortDescription;
      state.form.metaTitle = action.payload.metaTitle;
      state.form.metaDescription = action.payload.metaDescription;
      state.form.title = action.payload.title;
      if (action.payload.image) {
        state.form.imageStatus.push({
          image: action.payload.image,
          isDelete: false
        });
      } else {
        state.form.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
    },
    doAddBlogImage: (state, action: PayloadAction<{ file: any; callback: any }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
export { initialState };
