import {
  CategoryTypeEnum,
  DealershipInvestmentTypeEnum,
  DealershipTypeEnum,
  InitialListState,
  InquiryStatusEnum,
  InquiryTypeEnum,
  ProductTypeEnum,
  PurchaseTimeTypeEnum,
  SegmentTypeEnum,
  ShowroomStatusEnum,
  SourceTypeEnum
} from '.';

export const initialState: InitialListState = {
  List: [],
  emailNewsList: [],
  enquiryList: [],
  loading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  emailSearch: '',
  enquirySearch: '',
  emailNewsPageNo: 1,
  emailNewsPageSize: 10,
  emailNewsTotalRow: 0,
  emailNewsSortColumn: '',
  emailNewsSortDir: '',
  enquiryPageNo: 1,
  enquiryPageSize: 10,
  enquiryTotalRow: 0,
  enquirySortColumn: '',
  enquirySortDir: '',
  category: CategoryTypeEnum.All,
  segment: SegmentTypeEnum.All,
  source: SourceTypeEnum.All,
  enquiryDetails: {
    _id: '',
    fullName: '',
    email: '',
    dealershipType: DealershipTypeEnum.E2W,
    mobileNumber: '',
    investmentType: DealershipInvestmentTypeEnum['20 Lakh - 30 lakhs'],
    showroomStatus: ShowroomStatusEnum.NOT_READY,
    status: InquiryStatusEnum.Inquiry,
    updatedAt: '',
    inquiryType: InquiryTypeEnum.CUSTOMER,
    state: '',
    city: '',
    interestedProduct: ProductTypeEnum.DRIXX,
    purchasingTime: PurchaseTimeTypeEnum['2-3 Weeks'],
    category: CategoryTypeEnum.All,
    segment: SegmentTypeEnum.All,
    source: SourceTypeEnum.All,
    utm_source: '',
    utm_medium: '',
    campaign_id: '',
    adgroup_id: '',
    Keyword_Id: '',
    Network: '',
    Device: '',
    Matchtype: '',
    Location: '',
    Keyword: '',
    Placement: '',
    Ad_Position: '',
    gcl_Id: '',
    createdAt: '',
  }
};
